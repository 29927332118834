import React, { useCallback, useMemo } from 'react';
import {
  Alert,
  Button,
  Col,
  CopyTooltip,
  IconButton,
  Input,
  LinearTable,
  MuiIcons,
  Paper,
  PrimaryTextSpan,
  RdpIcon,
  Row,
  styled,
} from 'elements';
import { stringify } from 'query-string';
import { useTranslation } from 'react-i18next';
import {
  ALL_TEST_IDS,
  defaultRdsNavQuery,
  GLOBAL_DATE_TIME_FORMAT,
} from 'enums';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import {
  usePermissions,
  useQuery,
  useState,
  useTask,
  useUserHash,
} from 'hooks';
import { debounce, openInNewTab, remCalc, stripCoreDomain } from 'utils';
import { MaintenanceWindowValidatePayload } from 'maintenances';
import cn from 'classnames';
import ButtonList from 'components/ActionButtons/ButtonList';
import SnapshotInfo from 'components/SnapshotInfo';
import Actions from '../Actions';
import { InjectedProps, withRdsActions } from 'hocs/withRdsActions';
import EditPerformanceProfileDialog from './EditPerformanceProfileDialog';
import GeoipAccessDialog from './GeoipAccessDialog';
import ProfileDisk from './ProfileDisk';
import CoreSnapshotBanner from './CoreSnapshotBanner';
import { USERS_COLUMNS } from '../constants';
import { downloadRdpFile } from '../helpers';
import { dayjs } from 'utils/dayjsExtended';
import { DiskSizeHolder } from '../Styled';

type IDialogType = 'performance' | null;

type IProps = InjectedProps & {
  requireAgentUpdate?: boolean;
};

type IState = {
  openedDialog: IDialogType;
  geoipDialogOpen: boolean;
};

const HIDE_GEO_IP = true;

const INITIAL_STATE = {
  openedDialog: null,
  geoipDialogOpen: false,
};

const OpenInNewTabIcon = styled(MuiIcons.OpenInNew)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const OBSERVERS = {
  rds: StateHandlers.rds,
  users: StateHandlers.rdsUsers,
  core: StateHandlers.core,
  currentUser: StateHandlers.currentUser,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    handlers,
    actionItems,
    rds,
    users,
    core,
    currentUser,
    currentSnapshot,
  } = props;
  const { t } = useTranslation();
  const { changeQuery, queryStr, query } = useQuery();
  const [, changeUrl] = useUserHash();
  const { permissions } = usePermissions('SERVICES');
  const [state, handleChange] = useState<IState>(INITIAL_STATE);
  const handleDialogChange = useCallback(
    (type: IDialogType) => handleChange({ openedDialog: type }),
    []
  );

  const searchRef = React.useRef({ value: query.q || '' });

  const fetchRds = useCallback(() => rds.get({}), []);

  const task = useTask(rds.data?.task || null, {
    onComplete: (action) => {
      fetchRds();
      if (action === 'delete') {
        changeUrl('/services/all');
      }
    },
  });

  const coreDomain = useMemo(
    () => (core.data ? stripCoreDomain(core.data.domainName) : ''),
    [core.data]
  );

  const changeSearchUrl = React.useCallback(
    (q: string) => changeQuery({ ...query, q, page: 1 }),
    [queryStr, query]
  );

  const onItemSearch = React.useCallback(
    debounce(
      (ev: React.ChangeEvent<HTMLInputElement>) =>
        changeSearchUrl(ev.target.value),
      1000
    ),
    [queryStr, query]
  );

  const columns = useMemo(() => USERS_COLUMNS({ t, coreDomain }), []);

  const onUsersChange = useCallback(
    (payload: MaintenanceWindowValidatePayload) => {
      users.create(payload).then(() => {
        users.get(query);
        handleDialogChange(null);
      });
    },
    [queryStr]
  );

  const isOpenDialog = state.openedDialog === 'performance';

  const createdSnapshot = !currentSnapshot?.schedule
    ? currentSnapshot
    : undefined;
  const scheduledSnapshot = currentSnapshot?.schedule
    ? currentSnapshot
    : undefined;

  const sourceCountryCodesDisplayText = rds.data?.sourceCountryCodes?.length
    ? rds.data.sourceCountryCodes.join(', ')
    : t('common.any');

  React.useEffect(() => {
    searchRef.current.value = query.q || '';
  }, [query.q]);

  React.useEffect(() => {
    users.get(query);
  }, [queryStr]);

  return (
    <Paper
      className={cn('p-30')}
      style={{ minHeight: `calc(100vh - ${remCalc(250)})` }}
    >
      <Row className="mb-10" alignItems="center" justifyContent="space-between">
        <Col>
          <div className="uppercase bolder fs-14">
            {t('services.rds.users.title')}
          </div>
        </Col>
        {permissions.canManage && (
          <Col>
            <Row columnSpacing={2} alignItems="center">
              <Col>
                <ButtonList actions={actionItems || []} />
              </Col>
              <Col>
                <Actions task={task} handleChange={handleChange} />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row
        className="mb-30 fs-14 full-width"
        columnSpacing={5}
        alignItems="center"
      >
        <Col>
          <div className="flex">
            <div className="mr-10">
              {t('services.rds.users.accessGateway')}:
            </div>
            <div className="steel">
              <CopyTooltip>{rds.data.externalFQDN}</CopyTooltip>
            </div>
            <OpenInNewTabIcon
              className="fs-20 ml-10 pointer"
              onClick={() => openInNewTab(`https://${rds.data.externalFQDN}`)}
            />
          </div>
        </Col>
        <Col>
          <div className="flex align-center">
            <div className="mr-10">{t('services.rds.users.downloadRdp')}:</div>
            <RdpIcon
              className={cn('fs-24 pointer radiused flex')}
              onClick={() =>
                downloadRdpFile(
                  rds.data,
                  `Remote Desktop ${
                    currentUser.data.tenant
                      ? currentUser.data.tenant.name
                      : `${currentUser.data.firstName} ${currentUser.data.lastName}`
                  }`
                )
              }
            />
          </div>
        </Col>
        {createdSnapshot && (
          <Col xs justifyContent="flex-end">
            <div className="flex justify-end full-width">
              <div>
                <SnapshotInfo snapshot={createdSnapshot} />
              </div>
            </div>
          </Col>
        )}
      </Row>

      {scheduledSnapshot && (
        <Alert severity="info" className="flex align-center mb-20">
          <Row columnSpacing={2} alignItems="center">
            <Col>
              {t('services.rds.alert.snapshot.planned.title', {
                startAt: dayjs(scheduledSnapshot.schedule?.startAt).format(
                  GLOBAL_DATE_TIME_FORMAT
                ),
              })}
            </Col>
            <Col>
              <Button
                size="small"
                color="default"
                variant="outlined"
                onClick={() =>
                  handlers.removeScheduledSnapshotTask(scheduledSnapshot)
                }
              >
                {t('services.content.action.removeScheduledSnapshotTask')}
              </Button>
            </Col>
          </Row>
        </Alert>
      )}
      <Row>
        <Col>
          <ProfileDisk />
        </Col>
        {!HIDE_GEO_IP && (
          <Col>
            <DiskSizeHolder className="flex align-center fs-14">
              <div className="mr-10">
                {t('services.rds.users.limitAccess')}:
              </div>
              <PrimaryTextSpan className="bolder">
                {sourceCountryCodesDisplayText}
              </PrimaryTextSpan>
              <IconButton
                size="small"
                onClick={() => handleChange({ geoipDialogOpen: true })}
                className={cn({
                  disabled: rds.isRequesting,
                })}
              >
                <MuiIcons.Edit className="fs-18" />
              </IconButton>
            </DiskSizeHolder>
          </Col>
        )}
      </Row>
      <CoreSnapshotBanner />
      <Row className="mb-15" justifyContent="space-between" alignItems="center">
        <Col>
          <div className="uppercase bolder fs-14">
            {t('services.rds.users.tableTitle')}
          </div>
        </Col>
        {permissions.canManage && (
          <Col>
            <Row columnSpacing={2}>
              <Col>
                <Button
                  size="small"
                  onClick={() => handleDialogChange('performance')}
                  testId={ALL_TEST_IDS.services.remote.enableUsers}
                >
                  {t('services.rds.users.enableUsers')}
                </Button>
              </Col>
              <Col>
                <Button
                  size="small"
                  onClick={() =>
                    changeUrl(
                      `/services/infrastructure/accounts?${stringify(
                        defaultRdsNavQuery
                      )}`
                    )
                  }
                  testId={ALL_TEST_IDS.services.remote.manageUsers}
                >
                  {t('services.rds.users.manageUsers')}
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <div className="mb-5">
        <Input
          placeholder={t('forms.placeholders.search')}
          ref={searchRef}
          type="search"
          inputClassName={cn('bg-white')}
          inputProps={{ style: { height: remCalc(48) } }}
          className="mt-25"
          onKeyUp={(e: any) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              changeSearchUrl(e.target.value);
            }
          }}
          onClear={() => {
            searchRef.current.value = '';
            changeSearchUrl('');
          }}
          onChange={onItemSearch}
        />
      </div>
      <LinearTable
        columns={columns}
        data={users.data}
        params={users.meta || {}}
        query={query}
        isLoading={!users.dataReceived}
        onPageChange={(nq) => changeQuery({ ...defaultRdsNavQuery, ...nq })}
        testId={ALL_TEST_IDS.services.remote.users}
        noDataMessage={t('services.rds.users.table.noUsers')}
        additionalTaskWatch="rdsUser"
        usePagination
        hasSorting
        onSort={(orderBy, orderType) => changeQuery({ orderBy, orderType })}
        onUpdateTable={() => users.get(query)}
      />
      <GeoipAccessDialog
        open={state.geoipDialogOpen}
        onClose={() => handleChange({ geoipDialogOpen: false })}
        onSave={() => undefined}
      />
      <EditPerformanceProfileDialog
        open={isOpenDialog}
        testId={ALL_TEST_IDS.services.remote.usersDialog}
        onClose={() => handleDialogChange(null)}
        onSave={onUsersChange}
        coreDomain={coreDomain}
      />
    </Paper>
  );
});

const Users = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default withRdsActions(Users);
