import {
  IRds,
  IRdsGeoipAccessPayload,
  IRdsHost,
  IRdsProfileDiskUpdatePayload,
  IRdsUser,
  MaintenanceWindowPayload,
} from 'rds';
import * as R from 'ramda';
import {
  MaintenanceWindowValidatePayload,
  MaintenanceWindowValidateResponse,
} from 'maintenances';
import { DELETE, GET, POST, PUT } from 'utils';
import { AxiosPromise } from 'axios';
import { IAxiosPromise } from 'axios-shapes';

export const get = (params?: Record<string, any>): AxiosPromise<IRds> =>
  GET(
    '/rds',
    R.mergeDeepRight(params || {}, {
      include: ['serviceMonitoring', 'serviceSnapshot', 'servicePatching'],
    })
  );

export const update = (
  payload: MaintenanceWindowValidatePayload
): AxiosPromise<IRds> => PUT('/rds', {}, payload);

export const updateMaintenance = (
  id: number,
  payload: MaintenanceWindowValidatePayload
): AxiosPromise<IRds> => PUT(`/maintenance-window/${id}`, {}, payload);

export const getActiveUsers = (
  params?: Record<string, any>
): IAxiosPromise<IRdsUser[]> => GET('/rds/users', params);

export const removeUserRds = (userId: number): AxiosPromise<Task> =>
  DELETE(`/rds/users/${userId}`);

export const remove = (deletionReason: string): AxiosPromise<Task> =>
  DELETE('/rds', {}, { deletionReason });

export const deploy = (): AxiosPromise<IRds> => POST('/rds');

export const powerOn = (): AxiosPromise<IRds> => POST(`/rds/action/power-on`);

export const powerOff = (): AxiosPromise<IRds> => POST(`/rds/action/power-off`);

export const reset = (): AxiosPromise<IRds> => POST(`/rds/action/reset`);

export const shutDown = (): AxiosPromise<IRds> => POST(`/rds/action/shutdown`);
export const reboot = (winds: IMaintenanceWindow[]): AxiosPromise<IRds> =>
  POST(
    `/rds/action/reboot`,
    {},
    { maintenanceWindows: [...winds, { isForced: true }] }
  );

export const getHost = (id: number): AxiosPromise<IRdsHost> =>
  GET(`/rds/${id}/host`);

export const validate = (
  payload: MaintenanceWindowValidatePayload
): AxiosPromise<MaintenanceWindowValidateResponse> =>
  POST(`/rds/validate`, {}, payload);

export const updateProfileDisk = (
  payload: IRdsProfileDiskUpdatePayload
): AxiosPromise<IRds> => PUT('/rds/profile-disk', {}, payload);

export const upgradeApps = (
  payload: MaintenanceWindowPayload
): AxiosPromise<IRds> =>
  POST(`/rds/apps/upgrade`, {}, { maintenanceWindow: payload });

export const createNewReboot = (
  payload: MaintenanceWindowPayload[]
): AxiosPromise<IRds> =>
  POST(`/rds/action/reboot`, {}, { maintenanceWindows: payload });

export const updateReboot = (
  id: number,
  payload: MaintenanceWindowPayload
): AxiosPromise<IRds> => PUT(`/maintenance-window/${id}`, {}, payload);

export const setGeoipRestriction = (
  payload: IRdsGeoipAccessPayload
): AxiosPromise<IRds> => PUT(`/rds/access`, {}, payload);

export const removeReboot = (id: number): AxiosPromise<IRds> =>
  DELETE(`/maintenance-window/${id}`);

export const setPatching = (
  payload: IPatchingTypes.Values
): AxiosPromise<IRds> => {
  const data = {
    alertEmailRecipient: payload.alertEmailRecipient,
    outsourceManagementEnabled: payload.outsourceManagementEnabled,
    maintenanceWindows: [
      {
        startAt: payload.startDate,
        isForced: false,
        notificationEnabled: payload.notificationEnabled,
        recurringInterval:
          payload.recurringInterval?.value === 'SINGLE' // as backend doesnt have SINGLE interval value, we need pass undefined instead
            ? null
            : payload.recurringInterval?.value,
      },
    ],
  };
  return POST(`/rds/patching/configure`, {}, data);
};
