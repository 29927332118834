import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import FormHelperText from '@mui/material/FormHelperText';
import { Button, Input, Loader, MuiIcons, Tooltip } from 'elements';
import { Trans, withTranslation } from 'react-i18next';
import { WithT } from 'i18next';
import { ALL_TEST_IDS, isDev, isTest } from 'enums';
import { useFormik } from 'formik';
import i18n from '../../../i18n';
import { IUserFormValues, privacyLinks } from './constants';
import { validateUserData } from './helpers';
import FormStepper from './FormStepper';

type IProps = React.PropsWithChildren<
  WithT & {
    showStepper: boolean;
    activeStep: number;
    initialValues: IUserFormValues;
    onSubmit: (val: IUserFormValues) => void;
  }
>;

const Renderer = (props: IProps) => {
  const { t, activeStep, showStepper, initialValues, onSubmit } = props;
  const recaptchaRef = React.useRef<ReCAPTCHA>();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    submitCount,
    isValidating,
    submitForm,
    setFieldValue,
  } = useFormik({
    initialValues,
    validate: validateUserData,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-40">
        <FormStepper
          showStepper={showStepper}
          handleNext={() => submitForm()}
          activeStep={activeStep}
        />
      </div>
      <Input
        testId={ALL_TEST_IDS.signUp.inputs}
        id="firstName"
        name="firstName"
        label={t('forms.firstName')}
        placeholder={t('forms.placeholders.firstName')}
        error={!!submitCount && !!errors.firstName}
        helperText={errors.firstName}
        helperTextOptions={{ max: 30 }}
        className="mb-25"
        onChange={handleChange}
        value={values.firstName}
        elementSize="lg"
        labelIcon={
          <sup>
            <Tooltip
              title={t('forms.sups.name')
                .split(';')
                .map((el) => (
                  <div key={el}>- {el}</div>
                ))}
            >
              <MuiIcons.InfoOutlined className="fs-12 primary ml-5" />
            </Tooltip>
          </sup>
        }
      />
      <Input
        testId={ALL_TEST_IDS.signUp.inputs}
        id="lastName"
        name="lastName"
        label={t('forms.lastName')}
        placeholder={t('forms.placeholders.lastName')}
        error={!!submitCount && !!errors.lastName}
        helperText={errors.lastName}
        helperTextOptions={{ max: 30 }}
        className="mb-25"
        onChange={handleChange}
        value={values.lastName}
        elementSize="lg"
        labelIcon={
          <sup>
            <Tooltip
              title={t('forms.sups.name')
                .split(';')
                .map((el) => (
                  <div key={el}>- {el}</div>
                ))}
            >
              <MuiIcons.InfoOutlined className="fs-12 primary ml-5" />
            </Tooltip>
          </sup>
        }
      />
      <Input
        testId={ALL_TEST_IDS.signUp.inputs}
        id="email"
        name="email"
        label={t('forms.businessEmail')}
        placeholder={t('forms.placeholders.businessEmail')}
        error={!!submitCount && !!errors.email}
        helperText={errors.email}
        className="mb-25"
        onChange={handleChange}
        value={values.email}
        errorId={
          errors.email === 'forms.emailInvalidSpam'
            ? 'spam-signup-user-email'
            : undefined
        }
        endAdornment={
          isValidating && (
            <div className="pr-10 pl-10">
              <Loader size={15} />
            </div>
          )
        }
        elementSize="lg"
      />
      {!(isTest || isDev) && (
        <div className="mb-25">
          <ReCAPTCHA
            ref={recaptchaRef as any}
            sitekey={process.env.REACT_APP_SITE_API_KEY as string}
            onChange={(captcha) => setFieldValue('captcha', captcha)}
          />
          {errors.captcha && (
            <FormHelperText error>{t(errors.captcha) as string}</FormHelperText>
          )}
        </div>
      )}

      <div className="fs-12 steel mb-20 lh-14">
        <Trans
          i18nKey="auth.registration.agreement"
          components={[
            <a
              href={privacyLinks[i18n.language]?.terms as any}
              className="primary"
              target="_blank"
              rel="noreferrer"
            >
              customer terms
            </a>,
            <a
              href={privacyLinks[i18n.language].policy}
              className="primary"
              target="_blank"
              rel="noreferrer"
            >
              privacy policy
            </a>,
          ]}
        >
          By continuing, you're agreeing to our{' '}
          <a
            href={privacyLinks[i18n.language].terms}
            className="primary"
            target="_blank"
            rel="noreferrer"
          >
            customer terms
          </a>{' '}
          of service,{' '}
          <a
            href={privacyLinks[i18n.language].policy}
            className="primary"
            target="_blank"
            rel="noreferrer"
          >
            privacy policy
          </a>
        </Trans>
      </div>
      <Button
        testId={ALL_TEST_IDS.signUp.continue}
        size="large"
        fullWidth
        disabled={isValidating}
        className="mb-20"
        type="submit"
      >
        {t('common.continue')}
      </Button>
    </form>
  );
};

const UserInfoForm = withTranslation()(Renderer);

export default UserInfoForm;
