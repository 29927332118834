import * as React from 'react';
import { ISnapshot } from 'snapshots';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { IRds } from 'rds';
import { CustomEvents, RDS_ACCOUNTS_DEFAULT_PARAMS } from 'enums';
import {
  compact,
  confirm,
  confirmDeleteWithReasons,
  Emitter,
  IConfirmDeleteReasonsRes,
  noop,
  parseConfirmDeleteWithReasonResponse,
  showSystemMessage,
} from 'utils';
import { AnyFunc } from 'global-shapes';
import { openWebConsoleWindow } from 'utils/openWebConsole';
import { useRdsMenuItems } from 'hooks/useInstanceMenuItems';
import SnapshotDialog from 'components/SnapshotDialog';
import { ActionOption, IActionHandlers } from 'components/ActionButtons/types';
import {
  IRemoteSupportResult,
  usePermissions,
  useRemoteSupport,
  useSnapshotRequest,
  useState,
  useStateHandler,
  useTask,
  useUserHash,
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { rdsService } from 'services';
import { RemoteSupportDialog } from 'components';
import { BaseDecorator } from 'states/types';

export type InjectedProps = {
  fetchCore: AnyFunc;
  isRequesting: boolean;
  handlers: IActionHandlers;
  onDelete: AnyFunc;
  currentSnapshot?: ISnapshot & IScheduledTasks.Task;
  menuItems?: ActionOption[];
  remoteSupport: IRemoteSupportResult;
  actionItems?: ActionOption[];
  instance: BaseDecorator<IRds, false>;
  requireAgentUpdate: boolean;
};

type IState = {
  remoteSupportOpen: boolean;
  rsActionType?: 'enable' | 'disable';
  snapshotDialogOpen: boolean;
};

const showError = (err: any) => showSystemMessage(err.message, 'error');

const initial: IState = {
  remoteSupportOpen: false,
  rsActionType: undefined,
  snapshotDialogOpen: false,
};

export function withRdsActions(Component: React.ComponentType<any>) {
  return observer((props: any) => {
    const { t } = useTranslation();
    const { isProvider } = usePermissions();
    const [, changeQuery] = useUserHash();
    const snapshots = useStateHandler(StateHandlers.snapshots);
    const scheduledTasks = useStateHandler(StateHandlers.scheduledTasks);
    const rds = useStateHandler(StateHandlers.rds);
    const agent = useStateHandler(StateHandlers.agent);
    const rdsReboots = useStateHandler(StateHandlers.rdsReboots);
    const rdsData = rds.data || undefined;

    const currentSnapshot = rds.data?.serviceSnapshot;

    const onMainTaskComplete = React.useCallback((action: string) => {
      rds.get();
      snapshots.get();
      scheduledTasks.get();
      action === 'delete' && changeQuery('/services/all');
    }, []);

    const snapshotCompleteCallback = React.useCallback(() => {
      snapshots.get();
      scheduledTasks.get();
      rds.get();
    }, []);

    const task = useTask(rdsData?.task, { onComplete: onMainTaskComplete });
    const snapshotTask = useTask(rdsData?.serviceSnapshot?.task, {
      onComplete: snapshotCompleteCallback,
    });

    const remoteSupport = useRemoteSupport({
      type: 'rds',
      onSuccess: rds.get,
    });

    const [state, handleState] = useState<IState>(initial);

    const { execute: confirmSnapshotRequest, isRequesting: isPendingSnapshot } =
      useSnapshotRequest(
        {
          serviceEntityId: rdsData?.id,
          serviceEntityName: 'Rds',
          appEntityId: rdsData?.appEntityId,
        },
        currentSnapshot?.id
      );

    const isRequesting =
      isPendingSnapshot ||
      rds.isRequesting ||
      task.isTaskActive ||
      snapshotTask.isTaskActive ||
      agent.isRequesting;

    const onDeleteRds = React.useCallback(
      () =>
        confirmDeleteWithReasons({
          title: t('services.rds.confirm.delete.title'),
          content: t('services.rds.confirm.delete.subtitle'),
          onSuccess: (res: IConfirmDeleteReasonsRes) =>
            rds
              .remove(parseConfirmDeleteWithReasonResponse(res))
              .then(() => changeQuery('/services/all')),
          onCancel: () => undefined,
          successLabel: 'common.remove',
          cancelLabel: 'common.cancel',
        }),
      []
    );

    const onRebootRds = React.useCallback(
      () =>
        confirm({
          title: t('services.rds.confirm.reboot.title'),
          content: t('services.rds.confirm.reboot.subtitle'),
          onSuccess: async () => {
            await rdsReboots.get({
              appEntityIds: [rds.data?.appEntityId],
              orderBy: 'startAt',
              orderType: 'asc',
            });
            return rds
              .executeRequest('reboot')(rdsReboots.data)
              .then(() => rds.get())
              .catch(showError);
          },
          onCancel: () => undefined,
          successLabel: 'common.yes',
          cancelLabel: 'common.no',
        }),
      [rds.data?.appEntityId, rdsReboots.data]
    );

    const onActionSuccess = React.useCallback(() => {
      rds.get();
    }, []);

    const agents = React.useMemo(() => {
      const gwAgent = rds.data?.gateway?.agent;
      const hostAgents =
        rds.data?.host?.hostInstances?.map((inst) => inst.agent) || [];
      const agents = compact([gwAgent, ...hostAgents]);
      const requiresUpdate = agents.some((a) => !!a?.requiresUpdate);

      return {
        ids: agents.map((ag) => ag.id),
        requiresUpdate,
      };
    }, [rds.data]);

    const requiresAgentUpdate = isProvider ? agents?.requiresUpdate : false;

    const updateAgents = React.useCallback(() => {
      return Promise.all(
        agents.ids.map((id) => agent.executeRequest('runAgentUpdate')(id))
      );
    }, [JSON.stringify(agents)]);

    const onRemoveScheduledSnapshot = React.useCallback(
      (task: IScheduledTasks.Task) => {
        confirm({
          title: 'services.vm.confirm.snapshot.scheduled.remove.title',
          content: 'services.vm.confirm.snapshot.scheduled.remove.content',
          onSuccess: () =>
            scheduledTasks
              .remove(task.id)
              .then((res) => {
                showSystemMessage(
                  'snapshots.scheduled.remove.success',
                  'success'
                );
                return res;
              })
              .then(onActionSuccess)
              .then(scheduledTasks.get),
          onCancel: () => undefined,
        });
      },
      []
    );

    const handlers = React.useMemo(() => {
      return {
        powerOn: () => {
          return rdsService.powerOn().then(onActionSuccess).catch(showError);
        },
        powerOff: () => {
          return confirm({
            title: t(`services.vm.confirm.powerOff.rds.title`),
            content: t(`services.vm.confirm.powerOff.rds.subtitle`),
            onSuccess: () =>
              rdsService.powerOff().then(onActionSuccess).catch(showError),
            onCancel: noop,
          });
        },
        reset: () => {
          return confirm({
            title: t(`services.vm.confirm.reset.rds.title`),
            content: t(`services.vm.confirm.reset.rds.subtitle`),
            onSuccess: () =>
              rdsService.reset().then(onActionSuccess).catch(showError),
            onCancel: noop,
          });
        },
        reboot: () => onRebootRds(),
        delete: () => onDeleteRds(),
        shutDown: () => {
          return confirm({
            title: t(`services.vm.confirm.shutDown.rds.title`),
            content: t(`services.vm.confirm.shutDown.rds.subtitle`),
            onSuccess: () =>
              rdsService.shutDown().then(onActionSuccess).catch(showError),
            onCancel: noop,
          });
        },
        console: openWebConsoleWindow,
        assignBadges: () => Emitter.emit(CustomEvents.assignDialog, rds.data),
        enableRemoteSupport: () => {
          handleState({
            rsActionType: 'enable',
            remoteSupportOpen: true,
          });
        },
        disableRemoteSupport: () => {
          handleState({
            rsActionType: 'disable',
            remoteSupportOpen: true,
          });
        },
        createSnapshot: () => handleState(true, 'snapshotDialogOpen'),
        revertSnapshot: () =>
          confirmSnapshotRequest('revert').then(() => rds.get()),
        removeSnapshot: () =>
          confirmSnapshotRequest('remove').then(() => rds.get()),
        removeScheduledSnapshotTask: onRemoveScheduledSnapshot,
        openDetails: () =>
          changeQuery('/services/rds/accounts', RDS_ACCOUNTS_DEFAULT_PARAMS),
        updateAgent: updateAgents,
      };
    }, [
      JSON.stringify(rds.data),
      onActionSuccess,
      updateAgents,
    ]) as IActionHandlers;

    const { menuItems, actionItems, snapshot } = useRdsMenuItems({
      instance: rds.data,
      isRequesting,
      remoteSupport,
      handlers,
      agent: {
        requiresUpdate: requiresAgentUpdate,
      },
    });

    return (
      <>
        <Component
          {...props}
          fetchCore={rds.get}
          remoteSupport={remoteSupport}
          isRequesting={isRequesting}
          onDelete={onDeleteRds}
          currentSnapshot={currentSnapshot || snapshot}
          handlers={handlers}
          menuItems={menuItems}
          actionItems={actionItems}
          instance={rds}
          requireAgentUpdate={requiresAgentUpdate}
        />

        <SnapshotDialog
          action="create"
          serviceEntityName="Rds"
          open={state.snapshotDialogOpen}
          onClose={() => handleState(false, 'snapshotDialogOpen')}
          onSave={(payload) =>
            confirmSnapshotRequest('create', payload).then(() => {
              handleState(false, 'snapshotDialogOpen');
              return rds.get();
            })
          }
        />

        <RemoteSupportDialog
          subtitle="remoteSupport.dialog.rds.subtitle"
          open={state.remoteSupportOpen}
          attributes={{
            rdsId: rdsData?.id,
            vmId: rdsData?.host?.hostInstances[0]?.id,
            osType: 'win',
          }}
          onSave={noop}
          type="rds"
          onClose={() => handleState(false, 'remoteSupportOpen')}
          onSuccess={rds.get}
        />
      </>
    );
  });
}
