import {
  captchaValidationSchema,
  emailValidationSchema,
  firstNameValidationSchema,
  IUserFormValues,
  lastNameValidationSchema,
} from './constants';
import { checkValidEmail } from 'services/auth';
import { checkIfEmailIsInUse } from 'services/users';
import { isDev, isTest } from 'enums';
import { IRegisterCustomerPayload } from 'customer-shapes';

export async function validateUserData(
  user: IUserFormValues
): Promise<Partial<IUserFormValues>> {
  const errors: Partial<IUserFormValues> = {};

  try {
    await firstNameValidationSchema.validate(user.firstName).catch((err) => {
      errors.firstName = err.errors[0];
    });

    await lastNameValidationSchema.validate(user.lastName).catch((err) => {
      errors.lastName = err.errors[0];
    });

    if (!(isTest || isDev)) {
      await captchaValidationSchema
        .validate(user.captcha || '')
        .catch((err) => {
          errors.captcha = err.errors[0];
        });
    }

    await emailValidationSchema.validate(user.email).catch((err) => {
      errors.email = err.errors[0];
    });

    const { data: emailData } = await checkValidEmail(user.email);
    const isInUse = await checkIfEmailIsInUse(user.email).then(
      (res) => !res.data.isFree
    );

    if (emailData && emailData.label === 'DECLINED')
      errors.email = 'forms.emailInvalidSpam';

    if (isInUse) errors.email = 'forms.invalid.emailIsNotFree';
  } catch (e) {
    console.log('Bad request', e);
  }

  return errors;
}

export const reformatCustomerPayloadForApi = (
  values: AnyShape,
  query: AnyShape
): IRegisterCustomerPayload => {
  return {
    shortName: values.shortName,
    name: values.name,
    lang: query.lang,
    address: {
      line1: values.line1,
      line2: values.line2,
      zipCode: values.zipCode,
      countryCode: values.countryCode.value,
      city: values.city,
    },
    admin: {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      showTooltips: true,
      lang: query.lang,
    },
  };
};
